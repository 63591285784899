import Navigatie from "../../components/navigaties";
import Footers from "../../components/footers";
import { Helmet } from "react-helmet";
import BreadCrumb from "../../components/breadcrumb";

function P_Student_Inschrijven(props) {
    function renderNav() {
        if (props.location.pathname.includes("student")) {
            return <Navigatie type="studenten" />;
        } else if (props.location.pathname.includes("bedrijf")) {
            return <Navigatie type="bedrijven" />;
        } else {
            return <Navigatie type="home" />;
        }
    }

    function renderBreadCrumb() {
        if (props.location.pathname.includes("student")) {
            return <BreadCrumb prev={[["/student", "Home"]]} now="Wefynd" />;
        } else if (props.location.pathname.includes("bedrijf")) {
            return <BreadCrumb prev={[["/bedrijf", "Home"]]} now="Wefynd" />;
        } else {
            return <BreadCrumb prev={[["/", "Home"]]} now="Wefynd" />;
        }
    }

    return (
        <div className="mr-t-33" style={{ color: "black" }}>
            <Helmet>
                <title>HR - Wefynd</title>
            </Helmet>
            {renderNav()}
            <div className="container w-71.5 xl-w-82.5 m-w-99">
                {renderBreadCrumb()}
                <div className="pd-4 mr-t-8">
                    <h1 className="mr-b-8">Schrijf je nu in voor het Job & Stage Event 2025!</h1>

                    <p>
                    Klik op de onderstaande banner van Wefynd om je registratie te voltooien.
                    </p>
                    <a href="https://www.wefynd.com/nl/evenement/hermes-gent-job-en-stage-event-2025" target="_blank" rel="noreferrer">
                        <img src="../images/jse2025/wefynd_desktop.png" alt="Wefynd" className="w-50 l-w-60 m-w-70 m-hidden mr-b-8 mr-t-5 border-r-15" />

                        <img src="../images/jse2025/wefynd_mobiel.png" alt="Wefynd" className="hidden w-50 xs-w-100 s-w-70 m-block center mr-b-8 mr-t-5 border-r-15" />
                    </a>
                    <p>
                        Tijdens het event kun je met je Wefynd-account eenvoudig de QR-codes van bedrijven scannen waarin je geïnteresseerd bent. Zo deel je snel en veilig jouw contactgegevens met deze bedrijven en zet je de eerste stap naar jouw toekomstige carrière!
                    </p>
                    <br />
                    <p>
                        Wat extra praktische informatie over het Job en Stage Event 2025 kan je <a href="https://recruitment.hermesgent.be/student/job-en-stage-event" style={{ color: "black" }}>hier</a> vinden.
                    </p>
                    <br />
                    <h2 >Schrijf je nu in voor de Interactieve workshop: Wie ben ik ? Welke soort job zoek ik?</h2>
                    <br />
                    <a href="https://www.wefynd.com/nl/evenement/hermes-gent-interactieve-workshop-wie-ben-ik-welke-soort-job-zoek-ik?" style={{ color: "black" }}>
                        https://www.wefynd.com/nl/evenement/hermes-gent-interactieve-workshop-wie-ben-ik-welke-soort-job-zoek-ik
                    </a>
                
                </div>
            </div>
            <Footers type="main" />
        </div>
    );
}

export default P_Student_Inschrijven;
